// extracted by mini-css-extract-plugin
export var ArtistDescription = "MartinWong-module--ArtistDescription--iEb3S";
export var ArtistInfos = "MartinWong-module--ArtistInfos--YU44Y";
export var ButtonWrapper = "MartinWong-module--ButtonWrapper --PEK0q";
export var CardWrapper = "MartinWong-module--CardWrapper--AytT8";
export var CarrouselWrapper2 = "MartinWong-module--CarrouselWrapper2--OOoFX";
export var Citations = "MartinWong-module--Citations--de1vS";
export var DescriptionWrapper = "MartinWong-module--DescriptionWrapper--F3cNs";
export var ImageWrapper = "MartinWong-module--ImageWrapper--TZCnk";
export var LinkWrapper = "MartinWong-module--LinkWrapper--F-iIB";
export var MobileProtrait = "MartinWong-module--MobileProtrait--UyH5M";
export var More = "MartinWong-module--More--2heA8";
export var MoreButton = "MartinWong-module--MoreButton--Fnoi0";
export var NameWrapper = "MartinWong-module--NameWrapper--XpS0a";
export var PdpWrapper = "MartinWong-module--PdpWrapper--ebhZl";
export var PhotosWrapper = "MartinWong-module--PhotosWrapper--rtohR";
export var ProfilWrapper = "MartinWong-module--ProfilWrapper--0V1T2";
export var TitleWrapper = "MartinWong-module--TitleWrapper--QQuQ0";
export var Wrapper = "MartinWong-module--Wrapper--9PHsf";